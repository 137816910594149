<template>
  <div class="accountContent" v-loading="isLoad">
    <div class="returnCrumbs">
      <router-link :to="{name:'personHome'}">个人中心</router-link>
      <span>></span>
      <el-button type="text" class="crumbsActive">用户信息</el-button>
    </div>
    <div class="companyInstall clearfix">
      <div class="companyControl" v-if="customerCount>0">
        <el-button type="primary" icon="el-icon-plus" @click="completeInfo(false)">认证企业</el-button>
        <el-button-group>
          <el-button :class="{'currentSelected':certificationStatus===''}" @click="setCertificationStatus('')">全部</el-button>
          <el-button :class="{'currentSelected':certificationStatus===0}" @click="setCertificationStatus(0)">认证中</el-button>
          <el-button :class="{'currentSelected':certificationStatus===1}" @click="setCertificationStatus(1)">已认证</el-button>
        </el-button-group>
        <div class="companySearch">
          <el-input placeholder="请输入公司名称或AppId进行搜索" v-model="queryKey" @keyup.enter.native="searchList" maxlength="60">
            <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
          </el-input>
        </div>
      </div>
      <div class="companyList" v-if="customerCount>0">
        <div class="companyCorrelation" v-for="(item,index) of customerList" :key="index">
          <div class="companyOperate">
            <el-button type="text" @click="unbindEnterprise(item.appId,item.customerId)" v-if="item.authStatus===1">解绑企业</el-button>
            <el-button type="text" @click="getAuth(item.authCustomerId)" v-if="item.appId&&item.authStatus===1">重新认证</el-button>
            <el-button type="text" v-if="item.authStatus===0" @click="cancelVerify(item.name,item.authCustomerId)">取消认证</el-button>
            <el-button type="text" @click="getAuth(item.authCustomerId)" v-if="item.authStatus!==1&&item.authStatus<3">继续认证</el-button>
            <el-button type="text" @click="wrongTips(item.bindWrongCustomerId)" v-if="item.authStatus===3">绑定企业</el-button>
          </div>
          <h2>
            <img src="@/assets/icons/productCenter/learnMore-icon1.png">
            <span :class="{'wrongName':item.authStatus===3}">{{item.name}}</span>
            <i :class="item.authStatus===1?'certified':'notCertified'" v-if="item.authStatus<3">
              <svg-icon :icon-class="item.authStatus===1?'certified':'notCertified'" />{{item.authStatus|authStatus}}
            </i>
            <i v-else class="certifiedWrong"><svg-icon icon-class="notCertified" />{{item.authStatus|authStatus}}</i>
          </h2>
          <p><span>API认证账号（AppId）</span>{{item.appId||'- -'}}</p>
          <p>
            <span>API认证密钥（AppSecret）</span>
            <span v-if="item.authStatus!==1">认证通过后可添加</span>
            <span v-if="item.authStatus===1&&!item.hasAppSecret&&!showSecretList[index].showSecret">暂无</span>
            <el-button type="text" class="increase" @click="addAppSecret(index,item.authStatus)" v-if="item.authStatus===1&&!item.hasAppSecret&&!showSecretList[index].showSecret">[添加]</el-button>
            <el-button type="text" @click="viewKey(item.appId)" v-if="item.hasAppSecret&&!$store.state.user.secretList[index]">[查看]</el-button>
            <span class="secretInfo" v-if="$store.state.user.secretList[index]">{{$store.state.user.secretList[index].secret}}</span>
            <el-button type="text" @click="copyColumnSn($store.state.user.secretList[index].secret)" v-if="$store.state.user.secretList[index]">[复制]</el-button>

            <label class="ipBox" v-if="showSecretList[index].showSecret">
              <el-input v-model="showSecretList[index].appSecret" placeholder="请输入API认证密钥（AppSecret）" size="small" maxlength="1000"></el-input>
              <el-button type="text" @click="saveAppSecret(index,item.appId)">[添加]</el-button>
            </label>
          </p>
          <p><span>法人</span>{{item.legalPersonName||'- -'}}</p>
          <p><span>统一社会信用代码</span>{{item.identNo||'- -'}}</p>
          <p><span>认证通过时间</span>{{item.authPassDate||'- -'}}</p>
          <div class="companyInfos">
            <span>用户IP白名单</span>
            <span v-if="item.authStatus!==1">认证通过后可添加</span>
            <span v-if="item.ipWhite&&item.ipWhite.length<1&&!showIpWhiteList[index].showIpWhite">暂无</span>
            <el-button type="text" class="increase" @click="addWhiteList(index,item.authStatus)" v-if="item.ipWhite&&item.ipWhite.length<1&&!showIpWhiteList[index].showIpWhite">[添加]</el-button>
            <div>
              <div v-if="item.ipWhite&&item.ipWhite.length>0">
                <label class="ipBox" v-for="(ip,index) of item.ipWhite" :key="index">
                  <el-input :value="ip" placeholder="最多可添加10个IP白名单" size="small" readonly></el-input>
                  <el-button type="text" @click="removeIp(ip,item.appId)">[删除]</el-button>
                </label>
              </div>
              <label class="ipBox" v-if="showIpWhiteList[index].showIpWhite||(item.ipWhite&&item.ipWhite.length>0&&item.ipWhite.length<=9)">
                <el-input v-model="showIpWhiteList[index].ip" placeholder="最多可添加10个IP白名单" size="small"></el-input>
                <el-button type="text" @click="saveIpWhite(index,item.appId)">[添加]</el-button>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="uncertifiedEnterprise" v-else>
        <dl>
          <dt><img src="@/assets/image/personCenter/emptyState.png"></dt>
          <dd>还未认证企业，<a @click="judgmentAuth">去认证</a></dd>
        </dl>
        <el-button type="primary" size="small" @click="judgmentAuth">企业认证</el-button>
      </div>
    </div>
    <el-dialog title="管理员手机号验证" destroy-on-close :close-on-click-modal="false" width="580px" @close="dialogClose" :visible.sync="keyShow" class="authentication" v-loading="codeLoad">
      <div class="verifyMobileBox">
        <div class="warn" v-if="verify_mobile_sended">
          为了保护帐号安全，需要进行短信验证，我们已发送一条验证码至 {{$store.state.user.userInfo.mobile}}
        </div>
        <div class="hint">
          请输入 {{$store.state.user.userInfo.mobile}} 收到的验证码
        </div>
        <div class="inputBox" :class="{err:verify_code_err}" :data-err="verify_code_err">
          <el-input v-model="verify_code" maxlength="6" @blur="checkVerifyCode" placeholder="请输入验证码" size="small">
          </el-input>
          <verifyCodeButton @send="sendVerifyCode"></verifyCodeButton>
        </div>
      </div>
      <span slot="footer" class="dialog-buttons">
        <el-button type="primary" size="small" @click="verifyUnbind" v-if="isUnbind">确定解绑</el-button>
        <el-button type="primary" size="small" @click="verifyViewSecret" v-else>确定查看</el-button>
      </span>
    </el-dialog>

    <entryInfo ref="entryInfo" :isPersonAuth="isPersonAuth" :authCustomerId="authCustomerId" />
  </div>
</template>

<script>
import request from '@/utils/request'
import entryInfo from './entryInfo.vue';
import { authStatus } from '@/utils/typeMatch';
import { isVercode, isIp } from '@/utils/validate'
import verifyCodeButton from '@/views/personCenter/components/verifyCodeButton'
export default {
  data () {
    return {
      isLoad: false,
      customerList: [],
      certificationStatus: '',
      queryKey: '',
      appSecret: '',
      ip: '',
      verify_code: '',
      keyShow: false,
      verify_code_err: false,
      viewAppId: '',
      verify_mobile_sended: false,
      codeLoad: false,
      isUnbind: false,
      secretList: [],
      showSecretList: [],
      showIpWhiteList: [],
      customerCount: 0,
      customerId: '',
      authCustomerId: '',
      isPersonAuth: '',
    }
  },
  filters: {
    authStatus
  },
  components: {
    verifyCodeButton,
    entryInfo
  },
  mounted () {
    this.getEnterprise()
  },
  methods: {
    setCertificationStatus (index) {
      this.certificationStatus = index
      this.getEnterprise()
    },
    searchList () {
      // if (this.queryKey.length > 1) {
      this.getEnterprise()
      // } else {
      //   this.$message.error('请输入公司名称或AppId进行搜索!');
      // }
    },
    getEnterprise () {
      this.isLoad = true
      let data = {
        authStatus: this.certificationStatus,
        queryKey: this.queryKey
      }
      this.$api.getCustomerByCondition(data).then(res => {
        this.isLoad = false
        this.customerList = res.data.customerList
        this.customerCount = res.data.count
        for (let i = 0; i < res.data.customerList.length; i++) {
          this.showSecretList.push({
            showSecret: false,
            appSecret: ''
          })
          this.showIpWhiteList.push({
            showIpWhite: false,
            ip: ''
          })
        }
      }).catch(() => {
        this.isLoad = false
      })
    },
    unbindEnterprise (appId, customerId) {
      this.keyShow = true
      this.viewAppId = appId
      this.customerId = customerId
      this.isUnbind = true
    },
    verifyUnbind () {
      let code = this.verify_code
      if (code.length === 0) {
        this.verify_code_err = '请输入验证码'
        return false
      } else if (!isVercode(code)) {
        this.verify_code_err = '请输入正确的验证码'
        return false
      }
      let data = {
        appId: this.viewAppId,
        customerId: this.customerId,
        smsToken: this.smsToken,
        verificationCode: this.verify_code
      }
      this.codeLoad = true
      this.$api.unbindCustomer(data).then((res) => {
        this.codeLoad = false
        if (res.code === '0') {
          this.$message({
            type: 'success',
            message: '解绑企业成功！',
            onClose: () => { }
          })
          this.getEnterprise()
        }
        this.dialogClose()
      }).catch(() => {
        this.codeLoad = false
      })
    },
    completeInfo (item, companyId) {
      this.authCustomerId = companyId
      this.isPersonAuth = item
      this.$refs.entryInfo.realNameVisible = true
    },
    viewKey (appId) {
      this.keyShow = true
      this.viewAppId = appId
      this.isUnbind = false
    },
    saveAppSecret (i, appId) {
      if (this.showSecretList[i].appSecret.length <= 0) {
        this.$message.error('请输入API认证密钥（AppSecret）')
        return false
      }
      let data = {
        appId: appId,
        appSecret: this.showSecretList[i].appSecret
      }
      this.isLoad = true
      this.$api.saveSecret(data).then(() => {
        this.isLoad = false
        this.showSecretList[i].showSecret = false
        this.getEnterprise()
      }).catch(() => {
        this.isLoad = false
      })
    },
    addAppSecret (i, authStatus) {
      if (this.customerList[i].appId === undefined) {
        this.$confirm('该企业未创建AppId，请解绑企业重新认证', '提示', {
          confirmButtonText: '好的',
          showCancelButton: false,
          type: 'warning'
        }).then(() => { }).catch(() => { })
        return false
      }
      if (authStatus === 1) {
        this.showSecretList[i].showSecret = true
        this.appSecret = ''
      } else {
        this.$message.error('认证通过后可添加')
      }
    },
    addWhiteList (i, authStatus) {
      if (this.customerList[i].appId === undefined) {
        this.$confirm('该企业未创建AppId，请解绑企业重新认证', '提示', {
          confirmButtonText: '好的',
          showCancelButton: false,
          type: 'warning'
        }).then(() => { }).catch(() => { })
        return false
      }
      if (authStatus === 1) {
        this.showIpWhiteList[i].showIpWhite = true
        this.ip = ''
      } else {
        this.$message.error('认证通过后可添加')
      }
    },
    saveIpWhite (i, appId) {
      if (this.showIpWhiteList[i].ip.length <= 0) {
        this.$message.error('请输入IP')
        return false
      } else if (!isIp(this.showIpWhiteList[i].ip)) {
        this.$message.error('请输入正确的IP')
        return false
      }
      let data = {
        appId: appId,
        ip: this.showIpWhiteList[i].ip
      }
      this.isLoad = true
      this.$api.addIpWhite(data).then(() => {
        this.isLoad = false
        this.showIpWhiteList[i].showIpWhite = false
        this.showIpWhiteList[i].ip = ''
        this.getEnterprise()
      }).catch(() => {
        this.isLoad = false
      })
    },
    dialogClose () {
      // 弹框关闭
      this.keyShow = false
      this.verify_mobile_sended = false
      this.verify_code_err = false
      this.verify_code = ''
      this.smsToken = ''
    },
    // 手机发送验证码
    sendVerifyCode ({ cbk, val }) {
      let fn = this.isUnbind ? this.$api.getCustomerUnbind : this.$api.getSecretSend
      fn(val).then(res => {
        this.verify_mobile_sended = true
        this.smsToken = res.data.sms_token
        cbk()
      })
    },
    // 验证码校验
    checkVerifyCode () {
      let code = this.verify_code
      if (code.length === 0) {
        this.verify_code_err = '请输入验证码'
        return false
      } else if (!isVercode(code)) {
        this.verify_code_err = '请输入正确的验证码'
        return false
      }
      this.verify_code_err = false
      return true
    },
    verifyViewSecret () {
      if (this.verify_code.length <= 0) {
        this.verify_code_err = '请输入验证码'
        return false
      } else if (!isVercode(this.verify_code)) {
        this.verify_code_err = '请输入正确的验证码'
        return false
      }

      let data = {
        appId: this.viewAppId,
        smsToken: this.smsToken,
        verificationCode: this.verify_code
      }
      this.codeLoad = true
      this.$api.viewSecret(data).then((res) => {
        this.codeLoad = false
        const secret = res.msg
        let secretList = this.$store.state.user.secretList
        // secret存储，页面跳转刷新可以继续查看；退出登录需要重新认证
        for (let i = 0; i < this.customerList.length; i++) {
          if (this.customerList[i].appId === this.viewAppId) {
            let data = {
              appId: this.viewAppId,
              secret: secret
            }
            secretList[i] = data
            this.$store.commit('user/setSecretList', secretList)
          }
        }
        this.dialogClose()
      }).catch(() => {
        this.codeLoad = false
      })
    },
    judgmentAuth () {
      if (!this.$store.state.user.userInfo.verified) {
        this.$confirm('请先完成管理员认证', '提示', {
          confirmButtonText: '好的',
          showCancelButton: false,
          type: 'warning'
        }).then(() => { }).catch(() => { })
      } else {
        this.completeInfo(false)
      }

    },
    wrongTips (customerId) {
      let data = {
        accountId: this.$store.state.user.userInfo.accountId,
        bindWrongCustomerId: customerId
      }
      this.$api.customerRebind(data).then(res => {
        if (res.code === '0') {
          this.$confirm(res.msg, '提示', {
            confirmButtonText: '好的',
            showCancelButton: false,
            type: 'success'
          }).then(() => {
            this.getEnterprise()
          }).catch(() => { })
        } else {
          this.$confirm(res.msg, '提示', {
            confirmButtonText: '好的',
            showCancelButton: false,
            type: 'warning'
          }).then(() => { }).catch(() => { })
        }
      }).catch(() => {
        this.isLoad = false
      })
    },
    copyColumnSn (columnSn) {
      let txt = columnSn
      let oInput = document.createElement('input')
      oInput.value = txt
      oInput.style.opacity = '0'
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand('Copy')
      this.$message.success('复制成功~')
      oInput.remove()
    },
    removeIp (ip, appId) {
      this.$confirm(`是否确认 [删除] 指定IP：${ip}`, '提示', {
        confirmButtonText: '确定',
        showCancelButton: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteIpWhite(appId, ip)
      }).catch(() => { })
    },
    deleteIpWhite (appId, ip) {
      this.isLoad = true
      let data = {
        appId: appId,
        ip: ip
      }
      this.$api.removeIpWhite(data).then(res => {
        this.isLoad = false
        if (res.code === '0') {
          this.$message.success(res.msg)
          this.getEnterprise()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        this.isLoad = false
      })
    },
    cancelVerify (name, authCustomerId) {
      this.$confirm(`确定取消认证？`, '提示', {
        confirmButtonText: '确定',
        showCancelButton: '取消',
        type: 'warning'
      }).then(() => {
        this.cancelAuth(authCustomerId)
      }).catch(() => { })
    },
    cancelAuth (authCustomerId) {
      this.isLoad = true
      this.$api.cancelVerify({ authCustomerId: authCustomerId }).then(res => {
        this.isLoad = false
        if (res.code === '0') {
          this.$message.success(res.msg)
          this.getEnterprise()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        this.isLoad = false
      })
    },
    getAuth (authCustomerId) {
      this.$api.getAuthUrl({
        "authCustomerId": authCustomerId,
        "isPersonAuth": false,
        "haveAgentAuth": false,
        "myAccountInfo": false,
        'isPortalAuth': true,
      }).then((res) => {
        request({
          url: res.data.longUrl,
          method: 'get',
          params: {},
        }).then(res => {
          this.realNameVisible = false
          window.location.href = res.data.redirectUrl
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.accountContent {
  min-height: 100%;
  padding: 9px 40px;
  background: #fff;
}
.companyInstall {
  padding-top: 8px;
}
.uncertifiedEnterprise {
  padding-top: 193px;
  text-align: center;
  dl {
    margin-bottom: 40px;
  }
  dt {
    margin-bottom: 16px;
  }
  img {
    width: 60px;
  }
  dd {
    font-size: 12px;
    font-weight: 400;
    color: #b0b4bd;
    line-height: 18px;
  }
  .el-button {
    padding: 8px 17px;
  }
}
.companyControl {
  padding-bottom: 16px;
  .el-button--primary {
    padding: 7px 17px;
    margin-right: 16px;
  }
}
.el-button-group {
  margin-right: 16px;
  > .el-button:not(:last-child) {
    margin: 0;
  }
  .el-button {
    padding: 7px 15px;
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }
  ::v-deep .currentSelected {
    background: #ffffff;
    border: 1px solid #1c67f3;
    span {
      color: #1c67f3;
    }
  }
}
.companySearch {
  display: inline-block;
  width: 272px;
  ::v-deep .el-input__inner {
    height: 32px;
    border-radius: 4px;
    line-height: 32px;
    padding: 0 12px;
  }
  ::v-deep .el-input-group__append {
    position: absolute;
    right: -4px;
    top: 7px;
    border: none;
    background: none;
  }
  .el-button {
    border: none;
    padding: 7px 10px;
    background: #fff;
  }
}

.companyList {
  display: flex;
  flex-wrap: wrap;
}
.companyCorrelation {
  width: 698px;
  // height: 376px;
  background: #ffffff;
  border: 1px solid #dee0e9;
  padding: 24px 32px;
  margin-right: 40px;
  margin-bottom: 40px;
  position: relative;
  h2 {
    font-size: 14px;
    color: #303b50;
    line-height: 60px;
    font-weight: normal;
    margin-bottom: 26px;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      max-width: 459px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .wrongName {
      max-width: 260px;
    }
  }
  img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-right: 16px;
  }
  i {
    display: inline-block;
    // width: 67px;
    height: 24px;
    padding: 4px 11px 3px 8px;
    line-height: 15px;
    margin-left: 16px;
    border-radius: 2px;
    font-size: 11px;
  }
  .svg-icon {
    margin-right: 4px;
  }
  .certified {
    color: #52c41a;
    background: #eff9eb;
    border: 1px solid #d4f0c8;
  }
  .notCertified {
    color: #e89e1c;
    background: #fef8ec;
    border: 1px solid #fbbc4d;
  }
  .certifiedWrong {
    color: #ff5846;
    background: #ffeeee;
    border: 1px solid #f7caca;
  }
  p {
    height: 32px;
    font-size: 14px;
    color: #303b50;
    line-height: 32px;
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
    span {
      &:first-child {
        display: inline-block;
        width: 200px;
        color: #6c737b;
      }
    }
  }
  .secretInfo {
    width: 390px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
  }
  .ipWhite {
    display: inline-block;
    max-width: 378px;
    word-break: break-all;
    vertical-align: top;
  }
  .el-input {
    width: 272px;
    // margin-right: 8px;
  }
  ::v-deep .el-input__inner {
    padding-right: 50px;
  }
  ::v-deep .err .el-input__inner {
    border-color: rgb(255, 88, 70);
    position: relative;
  }
  ::v-deep .el-button--default {
    &:focus {
      background: #fff;
      border: 1px solid #dee2e8;
      span {
        color: #333333;
      }
    }
  }
  .el-button--text {
    padding: 0;
  }
  .increase {
    margin-left: 16px;
  }
  .ipBox {
    display: inline-block;
    position: relative;
    .el-button--text {
      position: absolute;
      right: 8px;
      top: 7px;
    }
  }
}
.companyInfos {
  display: flex;
  font-size: 14px;
  color: #303b50;
  line-height: 32px;
  margin-bottom: 8px;
  span {
    &:first-child {
      display: inline-block;
      width: 200px;
      color: #6c737b;
      flex-shrink: 0;
    }
  }

  .ipBox {
    display: inline-block;
    position: relative;
    margin-bottom: 12px;
    .el-button--text {
      position: absolute;
      right: 8px;
      top: 7px;
    }
  }
}
.companyOperate {
  position: absolute;
  top: 24px;
  right: 24px;
  .el-button {
    padding: 0;
  }
  .el-button + .el-button {
    margin-left: 24px;
  }
}

.authentication {
  ::v-deep .el-dialog .el-dialog__header {
    margin: 0px 20px;
    padding: 16px 0px 18px;
    border-bottom: 1px solid rgb(222, 226, 232);
  }
  ::v-deep .el-dialog__title {
    height: 19px;
    line-height: 19px;
    font-size: 14px;
    font-weight: 600;
  }
  .verifyMobileBox {
    padding: 18px 82px;
    font-size: 14px;
    font-weight: 500;
  }
  .inputBox {
    width: 100%;
    position: relative;
    display: flex;
  }
  .warn {
    color: rgb(255, 88, 70);
    line-height: 26px;
  }
  .hint {
    line-height: 14px;
    margin: 16px 0px;
    color: rgb(48, 59, 80);
  }
  ::v-deep .inputBox.err .el-input__inner {
    border-color: rgb(255, 88, 70);
    position: relative;
  }
  .inputBox.err::after {
    content: attr(data-err);
    position: absolute;
    left: 0px;
    line-height: 12px;
    bottom: -20px;
    font-size: 12px;
    color: rgb(255, 88, 70);
  }
}
</style>